<template>
  <div>
    <div class="view-header pt-1">
      <h2 class="heading-medium">Affiliate Report</h2>

      <div
        v-if="loaded"
        class="flex flex-column mt-4">
        <div class="flex">
          <validation-observer
            ref="report-filters-form"
            tag="form"
            class="search-form flex flex-align-items-center">
            <div class="form-group mb-0">
              <validation-provider
                name="start-at"
                v-slot="{ errors }">
                <div
                  :class="{
                    'is-date-range': true,
                    'vs-wrap-text': true,
                    'is-invalid': errors.length
                  }">
                  <v-date-picker
                    v-model="from_date"
                    mode="date"
                    locale="en-gb"
                    class="date"
                    :max-date='to_date'
                    :popover="{ visibility: 'focus' }">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        :value="inputValue"
                        v-on="inputEvents"
                        placeholder="From"
                        class="form-control f-input" />
                    </template>
                  </v-date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </div>
              </validation-provider>
            </div>

            <div class="form-group ml-1 mb-0">
              <validation-provider
                name="end-at"
                v-slot="{ errors }">
                <div
                  :class="{
                    'is-date-range': true,
                    'vs-wrap-text': true,
                    'is-invalid': errors.length
                  }">
                  <v-date-picker
                    v-model="to_date"
                    mode="date"
                    locale="en-gb"
                    class="date"
                    :min-date='from_date'
                    :popover="{ visibility: 'focus' }" >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        :value="inputValue"
                        v-on="inputEvents"
                        placeholder="To"
                        class="form-control f-input" />
                    </template>
                  </v-date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </div>
              </validation-provider>
            </div>
          </validation-observer>
          <f-button
            theme="light"
            class="no-shrink mb-3 mt-2 ml-2"
            @click.prevent="generateReport">
            Generate Report
          </f-button>
        </div>

      </div>
    </div>

    <f-list
      v-if="loaded"
      ref="report-list"
      class="report-list"
      :loaded="loaded"
      :listSettings="listSettings"
      :listItems="reportItems" />

    <pagination
      :class="{'is-hidden': !loaded}"
      :meta-data="paginationMetaData" />

    <div
      v-if="!loaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>
  </div>
</template>


<script>
import Vue from 'vue';
import HasPagination from '@/mixins/HasPagination.vue';
import Pagination from '@/components/core/Pagination.vue';

export default {
  name: 'affiliate-report',
  mixins: [
    HasPagination
  ],
  components: {
    'pagination': Pagination
  },
  data () {
    return {
      listSettings: [
        {
          title: 'Ref Code',
          variableName: ['ref_code'],
          type: 'String',
          additionalClass: ['full-on-hover']
        },
        {
          title: 'First Name ',
          variableName: ['first_name'],
          type: 'String'
        },
        {
          title: 'Last Name',
          variableName: ['last_name'],
          type: 'String'
        },
        {
          title: 'Submissions',
          variableName: ['submissions'],
          type: 'String'
        },
        {
          title: 'Pending',
          variableName: ['pending'],
          type: 'String'
        },
        {
          title: 'Approved',
          variableName: ['approved'],
          type: 'String'
        },
        {
          title: 'Declined',
          variableName: ['declined'],
          type: 'String'
        },

      ],
      reportItems: [],
      from_date: null,
      to_date: null,
      loaded: false,
      loadError: false,
      currentErrors: []
    }
  },
  mounted () {
    this.to_date = new Date();
    this.from_date = new Date(this.to_date);
    this.from_date.setDate(this.from_date.getDate() - 30);

    this.generateReport();
  },
  methods: {
    generateReport () {
      Vue.set(this, 'loaded', false);
      let formDataToSend = {};

      if (this.from_date) {
        formDataToSend.from_date = this.formatDate(this.from_date);
      }

      if (this.to_date) {
        formDataToSend.to_date = this.formatDate(this.to_date);
      }

      this.$http.post('/api/video/affiliate-report', formDataToSend).then((response) => {
        Vue.set(this, 'loaded', true);

        if (('success' in response.data) && response.data.success === 0) {
          this.loadError = true;
          if (response.data.message) {
            this.currentErrors = [];
            this.currentErrors.push({ error: response.data.message });
          }
          return;
        }
        Vue.nextTick(() => {
          Vue.set(this, 'reportItems', response.data.data);
        });
      }).catch((error) => {
        console.log(error);
        this.currentErrors = error.response.data.errors;
        this.submitting = false;
        this.loadError = true;
      });
    },
    formatDate (date) {
      if (!date) {
        return null;
      }

      let dateObject = new Date(date);

      return [
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      ].map(n => n < 10 ? '0' + n : n).join('-');
    }
  }
}
</script>
