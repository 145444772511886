var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"view-header pt-1"},[_c('h2',{staticClass:"heading-medium"},[_vm._v("Affiliate Report")]),(_vm.loaded)?_c('div',{staticClass:"flex flex-column mt-4"},[_c('div',{staticClass:"flex"},[_c('validation-observer',{ref:"report-filters-form",staticClass:"search-form flex flex-align-items-center",attrs:{"tag":"form"}},[_c('div',{staticClass:"form-group mb-0"},[_c('validation-provider',{attrs:{"name":"start-at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:{
                  'is-date-range': true,
                  'vs-wrap-text': true,
                  'is-invalid': errors.length
                }},[_c('v-date-picker',{staticClass:"date",attrs:{"mode":"date","locale":"en-gb","max-date":_vm.to_date,"popover":{ visibility: 'focus' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var inputValue = ref.inputValue;
                var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control f-input",attrs:{"placeholder":"From"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3614503176)})],1),_c('div',{staticClass:"form-group ml-1 mb-0"},[_c('validation-provider',{attrs:{"name":"end-at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{class:{
                  'is-date-range': true,
                  'vs-wrap-text': true,
                  'is-invalid': errors.length
                }},[_c('v-date-picker',{staticClass:"date",attrs:{"mode":"date","locale":"en-gb","min-date":_vm.from_date,"popover":{ visibility: 'focus' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var inputValue = ref.inputValue;
                var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control f-input",attrs:{"placeholder":"To"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,303074267)})],1)]),_c('f-button',{staticClass:"no-shrink mb-3 mt-2 ml-2",attrs:{"theme":"light"},on:{"click":function($event){$event.preventDefault();return _vm.generateReport.apply(null, arguments)}}},[_vm._v(" Generate Report ")])],1)]):_vm._e()]),(_vm.loaded)?_c('f-list',{ref:"report-list",staticClass:"report-list",attrs:{"loaded":_vm.loaded,"listSettings":_vm.listSettings,"listItems":_vm.reportItems}}):_vm._e(),_c('pagination',{class:{'is-hidden': !_vm.loaded},attrs:{"meta-data":_vm.paginationMetaData}}),(!_vm.loaded && !_vm.loadError)?_c('div',{staticClass:"loading-spinner-wrapper"},[_vm._m(0)]):_vm._e(),(_vm.loadError)?_c('f-alert',{staticClass:"my-5",attrs:{"visible":"","theme":"warning"}},[_c('span',[_vm._v(" Loading data failed. "),_c('a',{staticClass:"lnk lnk-alert",attrs:{"href":"javascript:window.location.reload();"}},[_vm._v(" Reload page ")]),_vm._v(" to try again. ")])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loading-spinner pb-3 pt-3"},[_c('span',{staticClass:"loading-spinner-content"},[_vm._v(" Loading data… ")])])}]

export { render, staticRenderFns }